export var ActivistActionCategory;
(function (ActivistActionCategory) {
    ActivistActionCategory["BeingBroadcaster"] = "BeingBroadcaster";
    ActivistActionCategory["CreateTestimonialVideos"] = "CreateTestimonialVideos";
    ActivistActionCategory["General"] = "General";
    ActivistActionCategory["HostParlorMeeting"] = "HostParlorMeeting";
    ActivistActionCategory["ShulSchoolLiaisons"] = "ShulSchoolLiaisons";
})(ActivistActionCategory || (ActivistActionCategory = {}));
export var ActivistCategory;
(function (ActivistCategory) {
    ActivistCategory["ExecutiveCommittee"] = "ExecutiveCommittee";
    ActivistCategory["LeadershipCircle"] = "LeadershipCircle";
    ActivistCategory["NationalSteeringCommittee"] = "NationalSteeringCommittee";
})(ActivistCategory || (ActivistCategory = {}));
export var ActivityType;
(function (ActivityType) {
    ActivityType["Deadline"] = "Deadline";
    ActivityType["Email"] = "Email";
    ActivityType["Event"] = "Event";
    ActivityType["Gift"] = "Gift";
    ActivityType["Mail"] = "Mail";
    ActivityType["Meeting"] = "Meeting";
    ActivityType["PhoneCall"] = "PhoneCall";
    ActivityType["Receipt"] = "Receipt";
    ActivityType["Reminder"] = "Reminder";
    ActivityType["Task"] = "Task";
    ActivityType["Text"] = "Text";
    ActivityType["ThankYou"] = "ThankYou";
})(ActivityType || (ActivityType = {}));
export var AddressType;
(function (AddressType) {
    AddressType["Home"] = "Home";
    AddressType["Other"] = "Other";
    AddressType["Summer"] = "Summer";
    AddressType["Work"] = "Work";
})(AddressType || (AddressType = {}));
export var AssociatedPersonPosition;
(function (AssociatedPersonPosition) {
    AssociatedPersonPosition["Admin"] = "Admin";
    AssociatedPersonPosition["BoardMember"] = "BoardMember";
    AssociatedPersonPosition["BoardPresident"] = "BoardPresident";
    AssociatedPersonPosition["Director_of_Finance"] = "Director_of_Finance";
    AssociatedPersonPosition["ExecutiveDirector"] = "ExecutiveDirector";
    AssociatedPersonPosition["Head_of_School"] = "Head_of_School";
    AssociatedPersonPosition["Parent"] = "Parent";
    AssociatedPersonPosition["SchoolPresident"] = "SchoolPresident";
    AssociatedPersonPosition["SupportStaff"] = "SupportStaff";
    AssociatedPersonPosition["Teacher"] = "Teacher";
})(AssociatedPersonPosition || (AssociatedPersonPosition = {}));
export var BCLevelStatus;
(function (BCLevelStatus) {
    BCLevelStatus["Downgraded"] = "Downgraded";
    BCLevelStatus["New"] = "New";
    BCLevelStatus["Retained"] = "Retained";
    BCLevelStatus["Upgraded"] = "Upgraded";
})(BCLevelStatus || (BCLevelStatus = {}));
export var BCLevelType;
(function (BCLevelType) {
    BCLevelType["CY"] = "CY";
    BCLevelType["FY"] = "FY";
})(BCLevelType || (BCLevelType = {}));
export var BillingPlanDistributionType;
(function (BillingPlanDistributionType) {
    BillingPlanDistributionType["ByAmount"] = "ByAmount";
    BillingPlanDistributionType["ByQuantity"] = "ByQuantity";
    BillingPlanDistributionType["Custom"] = "Custom";
})(BillingPlanDistributionType || (BillingPlanDistributionType = {}));
export var BillingPlanInterval;
(function (BillingPlanInterval) {
    BillingPlanInterval["BiWeekly"] = "BiWeekly";
    BillingPlanInterval["Monthly"] = "Monthly";
    BillingPlanInterval["Weekly"] = "Weekly";
    BillingPlanInterval["Yearly"] = "Yearly";
})(BillingPlanInterval || (BillingPlanInterval = {}));
export var BoardMemberTerm;
(function (BoardMemberTerm) {
    BoardMemberTerm["Apr2003Jun2004"] = "Apr2003Jun2004";
    BoardMemberTerm["Aug2012Mar2013"] = "Aug2012Mar2013";
    BoardMemberTerm["Dec2013Jan2014"] = "Dec2013Jan2014";
    BoardMemberTerm["Dec2015Dec2016"] = "Dec2015Dec2016";
    BoardMemberTerm["Jan2017Dec2018"] = "Jan2017Dec2018";
    BoardMemberTerm["Jan2017Jul2017"] = "Jan2017Jul2017";
    BoardMemberTerm["Jan2017Mar2017"] = "Jan2017Mar2017";
    BoardMemberTerm["Jan2019Dec2020"] = "Jan2019Dec2020";
    BoardMemberTerm["Jan2021Dec2022"] = "Jan2021Dec2022";
    BoardMemberTerm["Jan2023Dec2024"] = "Jan2023Dec2024";
    BoardMemberTerm["Jul1997Aug1998"] = "Jul1997Aug1998";
    BoardMemberTerm["Jul1997Jun1998"] = "Jul1997Jun1998";
    BoardMemberTerm["Jul1998Jun1999"] = "Jul1998Jun1999";
    BoardMemberTerm["Jul1999Jun2000"] = "Jul1999Jun2000";
    BoardMemberTerm["Jul2000Jun2001"] = "Jul2000Jun2001";
    BoardMemberTerm["Jul2001Jun2002"] = "Jul2001Jun2002";
    BoardMemberTerm["Jul2002Jun2003"] = "Jul2002Jun2003";
    BoardMemberTerm["Jul2003Jun2004"] = "Jul2003Jun2004";
    BoardMemberTerm["Jul2003Jun2016"] = "Jul2003Jun2016";
    BoardMemberTerm["Jul2004Jun2005"] = "Jul2004Jun2005";
    BoardMemberTerm["Jul2005Jun2006"] = "Jul2005Jun2006";
    BoardMemberTerm["Jul2014Jun2015"] = "Jul2014Jun2015";
    BoardMemberTerm["Jul2015Dec2016"] = "Jul2015Dec2016";
    BoardMemberTerm["Jul2015Feb2016"] = "Jul2015Feb2016";
    BoardMemberTerm["Jul2015Mar2016"] = "Jul2015Mar2016";
    BoardMemberTerm["Jul2015Sep2016"] = "Jul2015Sep2016";
    BoardMemberTerm["Jul2016Jun2016"] = "Jul2016Jun2016";
    BoardMemberTerm["Jun2007Jul2008"] = "Jun2007Jul2008";
    BoardMemberTerm["Jun2008Jul2009"] = "Jun2008Jul2009";
    BoardMemberTerm["Jun2009Jul2010"] = "Jun2009Jul2010";
    BoardMemberTerm["Jun2010Jul2011"] = "Jun2010Jul2011";
    BoardMemberTerm["Jun2011Jul2012"] = "Jun2011Jul2012";
    BoardMemberTerm["Mar2016Dec2016"] = "Mar2016Dec2016";
    BoardMemberTerm["Sep1998Jun1999"] = "Sep1998Jun1999";
})(BoardMemberTerm || (BoardMemberTerm = {}));
export var BroadcasterStatus;
(function (BroadcasterStatus) {
    BroadcasterStatus["Always"] = "Always";
    BroadcasterStatus["Never"] = "Never";
    BroadcasterStatus["Sometimes"] = "Sometimes";
})(BroadcasterStatus || (BroadcasterStatus = {}));
export var Commission;
(function (Commission) {
    Commission["CommunalResearchCenter"] = "CommunalResearchCenter";
    Commission["Communications"] = "Communications";
    Commission["CommunityProjects_and_Partnerships"] = "CommunityProjects_and_Partnerships";
    Commission["FinancialResourceDevelopment"] = "FinancialResourceDevelopment";
    Commission["ImpactAccelerator"] = "ImpactAccelerator";
    Commission["IsraelFreeSpirit"] = "IsraelFreeSpirit";
    Commission["JewishAction"] = "JewishAction";
    Commission["OU_Advocacy"] = "OU_Advocacy";
    Commission["OU_Israel"] = "OU_Israel";
    Commission["OU_JLIC"] = "OU_JLIC";
    Commission["OU_Kosher"] = "OU_Kosher";
    Commission["OU_Press"] = "OU_Press";
    Commission["SynagogueInitiative"] = "SynagogueInitiative";
    Commission["TeachCoalition"] = "TeachCoalition";
    Commission["TorahInitiatives"] = "TorahInitiatives";
    Commission["Womens_Initiatives"] = "Womens_Initiatives";
    Commission["Yachad"] = "Yachad";
    Commission["Youth"] = "Youth";
})(Commission || (Commission = {}));
export var Committee;
(function (Committee) {
    Committee["Audit"] = "Audit";
    Committee["Credentials"] = "Credentials";
    Committee["Executive"] = "Executive";
    Committee["Finance"] = "Finance";
    Committee["IT"] = "IT";
    Committee["Investment"] = "Investment";
    Committee["Legal"] = "Legal";
    Committee["Nominating_and_BoardResource"] = "Nominating_and_BoardResource";
    Committee["YoungLeadership"] = "YoungLeadership";
})(Committee || (Committee = {}));
export var ContactInfoType;
(function (ContactInfoType) {
    ContactInfoType["Address"] = "Address";
    ContactInfoType["Email"] = "Email";
    ContactInfoType["Phone"] = "Phone";
})(ContactInfoType || (ContactInfoType = {}));
export var ContactPreference;
(function (ContactPreference) {
    ContactPreference["Email"] = "Email";
    ContactPreference["Other"] = "Other";
    ContactPreference["Phone"] = "Phone";
    ContactPreference["Social_media"] = "Social_media";
    ContactPreference["Weekly_shabbat_shalom_newsletter"] = "Weekly_shabbat_shalom_newsletter";
})(ContactPreference || (ContactPreference = {}));
export var CreditCardType;
(function (CreditCardType) {
    CreditCardType["AmericanExpress"] = "AmericanExpress";
    CreditCardType["Discover"] = "Discover";
    CreditCardType["MasterCard"] = "MasterCard";
    CreditCardType["Visa"] = "Visa";
})(CreditCardType || (CreditCardType = {}));
export var CurrencyType;
(function (CurrencyType) {
    CurrencyType["CAD"] = "CAD";
    CurrencyType["NIS"] = "NIS";
    CurrencyType["USD"] = "USD";
})(CurrencyType || (CurrencyType = {}));
export var Department;
(function (Department) {
    Department["Advocacy"] = "Advocacy";
    Department["BenZakkai"] = "BenZakkai";
    Department["BringIsraelHome"] = "BringIsraelHome";
    Department["CommunalResearch"] = "CommunalResearch";
    Department["CommunityProjectsAndPartnerships"] = "CommunityProjectsAndPartnerships";
    Department["ECP"] = "ECP";
    Department["ImpactAccelerator"] = "ImpactAccelerator";
    Department["IsraelFreeSpirit"] = "IsraelFreeSpirit";
    Department["JLIC"] = "JLIC";
    Department["JLIC_AdminGroup"] = "JLIC_AdminGroup";
    Department["JLIC_Ariel"] = "JLIC_Ariel";
    Department["JLIC_Atlanta"] = "JLIC_Atlanta";
    Department["JLIC_BarIlan"] = "JLIC_BarIlan";
    Department["JLIC_BenGurion"] = "JLIC_BenGurion";
    Department["JLIC_Binghamton"] = "JLIC_Binghamton";
    Department["JLIC_BostonUniversity"] = "JLIC_BostonUniversity";
    Department["JLIC_Brandeis"] = "JLIC_Brandeis";
    Department["JLIC_BrooklynCollege"] = "JLIC_BrooklynCollege";
    Department["JLIC_CSUN"] = "JLIC_CSUN";
    Department["JLIC_Chicago"] = "JLIC_Chicago";
    Department["JLIC_Columbia"] = "JLIC_Columbia";
    Department["JLIC_Cornell"] = "JLIC_Cornell";
    Department["JLIC_Drexel"] = "JLIC_Drexel";
    Department["JLIC_IDCHerzliya"] = "JLIC_IDCHerzliya";
    Department["JLIC_Jerusalem"] = "JLIC_Jerusalem";
    Department["JLIC_JohnsHopkins"] = "JLIC_JohnsHopkins";
    Department["JLIC_Kahal"] = "JLIC_Kahal";
    Department["JLIC_MachonLev"] = "JLIC_MachonLev";
    Department["JLIC_MachonTal"] = "JLIC_MachonTal";
    Department["JLIC_McGill"] = "JLIC_McGill";
    Department["JLIC_McMaster"] = "JLIC_McMaster";
    Department["JLIC_Michigan"] = "JLIC_Michigan";
    Department["JLIC_NYU"] = "JLIC_NYU";
    Department["JLIC_Penn"] = "JLIC_Penn";
    Department["JLIC_Princeton"] = "JLIC_Princeton";
    Department["JLIC_Queens"] = "JLIC_Queens";
    Department["JLIC_Rutgers"] = "JLIC_Rutgers";
    Department["JLIC_SMC"] = "JLIC_SMC";
    Department["JLIC_TAU"] = "JLIC_TAU";
    Department["JLIC_TLV"] = "JLIC_TLV";
    Department["JLIC_Technion"] = "JLIC_Technion";
    Department["JLIC_UCLA"] = "JLIC_UCLA";
    Department["JLIC_UMASS"] = "JLIC_UMASS";
    Department["JLIC_UMD"] = "JLIC_UMD";
    Department["JLIC_UWM"] = "JLIC_UWM";
    Department["JLIC_Uillinois"] = "JLIC_Uillinois";
    Department["JLIC_WashingtonUniversity"] = "JLIC_WashingtonUniversity";
    Department["JLIC_Western"] = "JLIC_Western";
    Department["JLIC_Yale"] = "JLIC_Yale";
    Department["JLIC_Yavneh"] = "JLIC_Yavneh";
    Department["JLIC_York"] = "JLIC_York";
    Department["Job_Board"] = "Job_Board";
    Department["NCSY"] = "NCSY";
    Department["NCSY_AdminGroup"] = "NCSY_AdminGroup";
    Department["NCSY_Alumni"] = "NCSY_Alumni";
    Department["NCSY_Argentina"] = "NCSY_Argentina";
    Department["NCSY_Arizona"] = "NCSY_Arizona";
    Department["NCSY_Atlanta"] = "NCSY_Atlanta";
    Department["NCSY_AtlanticSeaboard"] = "NCSY_AtlanticSeaboard";
    Department["NCSY_Boston"] = "NCSY_Boston";
    Department["NCSY_BritishColumbia"] = "NCSY_BritishColumbia";
    Department["NCSY_Canada"] = "NCSY_Canada";
    Department["NCSY_CanadaGroup"] = "NCSY_CanadaGroup";
    Department["NCSY_CentralEast"] = "NCSY_CentralEast";
    Department["NCSY_CentralEastGroup"] = "NCSY_CentralEastGroup";
    Department["NCSY_Chile"] = "NCSY_Chile";
    Department["NCSY_Cleveland"] = "NCSY_Cleveland";
    Department["NCSY_Israel"] = "NCSY_Israel";
    Department["NCSY_LACity"] = "NCSY_LACity";
    Department["NCSY_LAValley"] = "NCSY_LAValley";
    Department["NCSY_LasVegas"] = "NCSY_LasVegas";
    Department["NCSY_Mexico"] = "NCSY_Mexico";
    Department["NCSY_Midwest"] = "NCSY_Midwest";
    Department["NCSY_MidwestGroup"] = "NCSY_MidwestGroup";
    Department["NCSY_Montreal"] = "NCSY_Montreal";
    Department["NCSY_NYNJ"] = "NCSY_NYNJ";
    Department["NCSY_NationalGroup"] = "NCSY_NationalGroup";
    Department["NCSY_NewEngland"] = "NCSY_NewEngland";
    Department["NCSY_NewJersey"] = "NCSY_NewJersey";
    Department["NCSY_NewYork"] = "NCSY_NewYork";
    Department["NCSY_Norcal"] = "NCSY_Norcal";
    Department["NCSY_OrangeCounty"] = "NCSY_OrangeCounty";
    Department["NCSY_Ottawa"] = "NCSY_Ottawa";
    Department["NCSY_ParkCityUtah"] = "NCSY_ParkCityUtah";
    Department["NCSY_Portland"] = "NCSY_Portland";
    Department["NCSY_ReliefMissions"] = "NCSY_ReliefMissions";
    Department["NCSY_STL"] = "NCSY_STL";
    Department["NCSY_SanDiego"] = "NCSY_SanDiego";
    Department["NCSY_Seattle"] = "NCSY_Seattle";
    Department["NCSY_Southern"] = "NCSY_Southern";
    Department["NCSY_Southwest"] = "NCSY_Southwest";
    Department["NCSY_Summer"] = "NCSY_Summer";
    Department["NCSY_Tristate"] = "NCSY_Tristate";
    Department["NCSY_UpstateNewYork"] = "NCSY_UpstateNewYork";
    Department["NCSY_Vancouver"] = "NCSY_Vancouver";
    Department["NCSY_WestCoast"] = "NCSY_WestCoast";
    Department["NCSY_WestCoastAreaBGroup"] = "NCSY_WestCoastAreaBGroup";
    Department["NCSY_WestCoastGroup"] = "NCSY_WestCoastGroup";
    Department["NextDor"] = "NextDor";
    Department["NextGen"] = "NextGen";
    Department["OU"] = "OU";
    Department["OUIsrael"] = "OUIsrael";
    Department["OUKosher"] = "OUKosher";
    Department["OUPress"] = "OUPress";
    Department["OU_Group"] = "OU_Group";
    Department["OU_Torah"] = "OU_Torah";
    Department["ReliefFunds"] = "ReliefFunds";
    Department["SynagogueInitiatives"] = "SynagogueInitiatives";
    Department["TeachCoalition"] = "TeachCoalition";
    Department["TeachCoalition_CA"] = "TeachCoalition_CA";
    Department["TeachCoalition_FL"] = "TeachCoalition_FL";
    Department["TeachCoalition_MD"] = "TeachCoalition_MD";
    Department["TeachCoalition_NJ"] = "TeachCoalition_NJ";
    Department["TeachCoalition_NV"] = "TeachCoalition_NV";
    Department["TeachCoalition_NYS"] = "TeachCoalition_NYS";
    Department["TeachCoalition_PA"] = "TeachCoalition_PA";
    Department["TeachCoalition_ProjectProtect"] = "TeachCoalition_ProjectProtect";
    Department["Teach_AdminGroup"] = "Teach_AdminGroup";
    Department["WestCoast"] = "WestCoast";
    Department["WomensInitative"] = "WomensInitative";
    Department["Yachad"] = "Yachad";
    Department["Yachad_AdminGroup"] = "Yachad_AdminGroup";
    Department["Yachad_Baltimore"] = "Yachad_Baltimore";
    Department["Yachad_Chicago"] = "Yachad_Chicago";
    Department["Yachad_Cleveland"] = "Yachad_Cleveland";
    Department["Yachad_Dallas"] = "Yachad_Dallas";
    Department["Yachad_Detroit"] = "Yachad_Detroit";
    Department["Yachad_Florida"] = "Yachad_Florida";
    Department["Yachad_Houston"] = "Yachad_Houston";
    Department["Yachad_IVDU"] = "Yachad_IVDU";
    Department["Yachad_Israel"] = "Yachad_Israel";
    Department["Yachad_LosAngeles"] = "Yachad_LosAngeles";
    Department["Yachad_Midwest"] = "Yachad_Midwest";
    Department["Yachad_NewEngland"] = "Yachad_NewEngland";
    Department["Yachad_NewJersey"] = "Yachad_NewJersey";
    Department["Yachad_NewYork"] = "Yachad_NewYork";
    Department["Yachad_Omaha"] = "Yachad_Omaha";
    Department["Yachad_OurWay"] = "Yachad_OurWay";
    Department["Yachad_Philadelphia"] = "Yachad_Philadelphia";
    Department["Yachad_Reach"] = "Yachad_Reach";
    Department["Yachad_Special_Initiatives"] = "Yachad_Special_Initiatives";
    Department["Yachad_Summer"] = "Yachad_Summer";
    Department["Yachad_Toronto"] = "Yachad_Toronto";
})(Department || (Department = {}));
export var DonorCategory;
(function (DonorCategory) {
    DonorCategory["BZCommittee2012"] = "BZCommittee2012";
    DonorCategory["BenZakkaiMember"] = "BenZakkaiMember";
    DonorCategory["BenefactorCircle"] = "BenefactorCircle";
    DonorCategory["BoardMember"] = "BoardMember";
    DonorCategory["DoNotCall"] = "DoNotCall";
    DonorCategory["DoNotContact"] = "DoNotContact";
    DonorCategory["DoNotEmail"] = "DoNotEmail";
    DonorCategory["DoNotMail"] = "DoNotMail";
    DonorCategory["DoNotText"] = "DoNotText";
    DonorCategory["GrantGiver"] = "GrantGiver";
    DonorCategory["HighCapacity"] = "HighCapacity";
    DonorCategory["LegacyGiftGiver"] = "LegacyGiftGiver";
    DonorCategory["LowCapacity"] = "LowCapacity";
    DonorCategory["MediumCapacity"] = "MediumCapacity";
    DonorCategory["NCSYAlumnus"] = "NCSYAlumnus";
    DonorCategory["NoExternalSolicitation"] = "NoExternalSolicitation";
    DonorCategory["OUACommittee"] = "OUACommittee";
    DonorCategory["OUEmployee"] = "OUEmployee";
    DonorCategory["OUMember"] = "OUMember";
    DonorCategory["OUNonMember"] = "OUNonMember";
    DonorCategory["OUSynagogue"] = "OUSynagogue";
    DonorCategory["OurWayMember"] = "OurWayMember";
    DonorCategory["Prospect"] = "Prospect";
    DonorCategory["RCAMember"] = "RCAMember";
    DonorCategory["Staff"] = "Staff";
    DonorCategory["Volunteer"] = "Volunteer";
})(DonorCategory || (DonorCategory = {}));
export var DonorGoalType;
(function (DonorGoalType) {
    DonorGoalType["Monetary"] = "Monetary";
    DonorGoalType["NonMonetary"] = "NonMonetary";
})(DonorGoalType || (DonorGoalType = {}));
export var DonorKind;
(function (DonorKind) {
    DonorKind["Company"] = "Company";
    DonorKind["Foundation"] = "Foundation";
    DonorKind["Household"] = "Household";
    DonorKind["School"] = "School";
    DonorKind["Shul"] = "Shul";
    DonorKind["Unset"] = "Unset";
})(DonorKind || (DonorKind = {}));
export var DonorSubGoalType;
(function (DonorSubGoalType) {
    DonorSubGoalType["Activist"] = "Activist";
    DonorSubGoalType["Ambassador_Network"] = "Ambassador_Network";
    DonorSubGoalType["Fundraising"] = "Fundraising";
    DonorSubGoalType["GOTV"] = "GOTV";
    DonorSubGoalType["GovernmentPrograms"] = "GovernmentPrograms";
    DonorSubGoalType["Grassroots"] = "Grassroots";
    DonorSubGoalType["ProgramInterests"] = "ProgramInterests";
    DonorSubGoalType["ProspectSchool"] = "ProspectSchool";
})(DonorSubGoalType || (DonorSubGoalType = {}));
export var DonorSubscription;
(function (DonorSubscription) {
    DonorSubscription["Belong"] = "Belong";
    DonorSubscription["Ignite"] = "Ignite";
    DonorSubscription["JewishAction"] = "JewishAction";
})(DonorSubscription || (DonorSubscription = {}));
export var DonorsReportType;
(function (DonorsReportType) {
    DonorsReportType["outdatedContact"] = "outdatedContact";
    DonorsReportType["withoutAddress"] = "withoutAddress";
    DonorsReportType["withoutSalutation"] = "withoutSalutation";
})(DonorsReportType || (DonorsReportType = {}));
export var EmailTemplateType;
(function (EmailTemplateType) {
    EmailTemplateType["OUMemebership"] = "OUMemebership";
    EmailTemplateType["ProjectProtect"] = "ProjectProtect";
})(EmailTemplateType || (EmailTemplateType = {}));
export var EmailType;
(function (EmailType) {
    EmailType["Other"] = "Other";
    EmailType["Personal"] = "Personal";
    EmailType["Work"] = "Work";
})(EmailType || (EmailType = {}));
export var EngagementReportEventType;
(function (EngagementReportEventType) {
    EngagementReportEventType["Anniversary"] = "Anniversary";
    EngagementReportEventType["Birthday"] = "Birthday";
    EngagementReportEventType["FirstGift"] = "FirstGift";
})(EngagementReportEventType || (EngagementReportEventType = {}));
export var ErrorType;
(function (ErrorType) {
    ErrorType["ImportModeSkip"] = "ImportModeSkip";
    ErrorType["Internal"] = "Internal";
    ErrorType["Projection"] = "Projection";
    ErrorType["WriteFailed"] = "WriteFailed";
})(ErrorType || (ErrorType = {}));
export var GOTVBroadcasterCategory;
(function (GOTVBroadcasterCategory) {
    GOTVBroadcasterCategory["Calls"] = "Calls";
    GOTVBroadcasterCategory["SocialMedia"] = "SocialMedia";
    GOTVBroadcasterCategory["Texting"] = "Texting";
})(GOTVBroadcasterCategory || (GOTVBroadcasterCategory = {}));
export var GOTVCommitteeCategory;
(function (GOTVCommitteeCategory) {
    GOTVCommitteeCategory["CommunityPrecinctTagging"] = "CommunityPrecinctTagging";
    GOTVCommitteeCategory["DoorKnockPhoneCall"] = "DoorKnockPhoneCall";
    GOTVCommitteeCategory["EmailBlastInfulencer"] = "EmailBlastInfulencer";
    GOTVCommitteeCategory["LawnSigns"] = "LawnSigns";
    GOTVCommitteeCategory["SchoolShulLiaison"] = "SchoolShulLiaison";
    GOTVCommitteeCategory["Tabling"] = "Tabling";
    GOTVCommitteeCategory["VoteBoosters"] = "VoteBoosters";
})(GOTVCommitteeCategory || (GOTVCommitteeCategory = {}));
export var Gender;
(function (Gender) {
    Gender["Female"] = "Female";
    Gender["Male"] = "Male";
})(Gender || (Gender = {}));
export var GrantApplicationType;
(function (GrantApplicationType) {
    GrantApplicationType["Application"] = "Application";
    GrantApplicationType["GeneralFunding"] = "GeneralFunding";
    GrantApplicationType["LOI"] = "LOI";
    GrantApplicationType["Proposal"] = "Proposal";
})(GrantApplicationType || (GrantApplicationType = {}));
export var GrantIdeaStatus;
(function (GrantIdeaStatus) {
    GrantIdeaStatus["Idea"] = "Idea";
    GrantIdeaStatus["NoGo"] = "NoGo";
    GrantIdeaStatus["Submitted"] = "Submitted";
})(GrantIdeaStatus || (GrantIdeaStatus = {}));
export var GrantStatus;
(function (GrantStatus) {
    GrantStatus["Approved"] = "Approved";
    GrantStatus["Closed"] = "Closed";
    GrantStatus["Denied"] = "Denied";
    GrantStatus["NoGo"] = "NoGo";
    GrantStatus["OnHold"] = "OnHold";
    GrantStatus["Pending"] = "Pending";
    GrantStatus["PreSubmission"] = "PreSubmission";
})(GrantStatus || (GrantStatus = {}));
export var IncomingPaymentType;
(function (IncomingPaymentType) {
    IncomingPaymentType["Payment"] = "Payment";
    IncomingPaymentType["Pledge"] = "Pledge";
})(IncomingPaymentType || (IncomingPaymentType = {}));
export var MailMergeTemplateType;
(function (MailMergeTemplateType) {
    MailMergeTemplateType["Invoice"] = "Invoice";
    MailMergeTemplateType["Receipt"] = "Receipt";
})(MailMergeTemplateType || (MailMergeTemplateType = {}));
export var NetworkCommentCategory;
(function (NetworkCommentCategory) {
    NetworkCommentCategory["Activist"] = "Activist";
    NetworkCommentCategory["Ambassador_Network"] = "Ambassador_Network";
    NetworkCommentCategory["Fundraising"] = "Fundraising";
    NetworkCommentCategory["GOTV_AdvisoryCommittee"] = "GOTV_AdvisoryCommittee";
    NetworkCommentCategory["Schools"] = "Schools";
})(NetworkCommentCategory || (NetworkCommentCategory = {}));
export var PPActivityStatus;
(function (PPActivityStatus) {
    PPActivityStatus["Canceled"] = "Canceled";
    PPActivityStatus["Completed"] = "Completed";
    PPActivityStatus["InProgress"] = "InProgress";
    PPActivityStatus["NotStarted"] = "NotStarted";
})(PPActivityStatus || (PPActivityStatus = {}));
export var PPActivityType;
(function (PPActivityType) {
    PPActivityType["GrantWriting"] = "GrantWriting";
    PPActivityType["Implementation"] = "Implementation";
    PPActivityType["Inquiry"] = "Inquiry";
})(PPActivityType || (PPActivityType = {}));
export var PaymentBank;
(function (PaymentBank) {
    PaymentBank["Canada"] = "Canada";
    PaymentBank["Credit_Cards"] = "Credit_Cards";
    PaymentBank["Fidelity"] = "Fidelity";
    PaymentBank["Israel"] = "Israel";
    PaymentBank["Main_OU"] = "Main_OU";
    PaymentBank["NCSY_ATL"] = "NCSY_ATL";
    PaymentBank["NCSY_ATS"] = "NCSY_ATS";
    PaymentBank["NCSY_Argentina"] = "NCSY_Argentina";
    PaymentBank["NCSY_CA"] = "NCSY_CA";
    PaymentBank["NCSY_CE"] = "NCSY_CE";
    PaymentBank["NCSY_MW"] = "NCSY_MW";
    PaymentBank["NCSY_MX"] = "NCSY_MX";
    PaymentBank["NCSY_NE"] = "NCSY_NE";
    PaymentBank["NCSY_NJ"] = "NCSY_NJ";
    PaymentBank["NCSY_NY"] = "NCSY_NY";
    PaymentBank["NCSY_OR"] = "NCSY_OR";
    PaymentBank["NCSY_STH"] = "NCSY_STH";
    PaymentBank["NCSY_STL"] = "NCSY_STL";
    PaymentBank["NCSY_SW"] = "NCSY_SW";
    PaymentBank["NCSY_Upstate"] = "NCSY_Upstate";
    PaymentBank["NCSY_WC"] = "NCSY_WC";
    PaymentBank["OU_West_Coast"] = "OU_West_Coast";
    PaymentBank["YACHAD_CA"] = "YACHAD_CA";
    PaymentBank["Yachad"] = "Yachad";
})(PaymentBank || (PaymentBank = {}));
export var PaymentBatchType;
(function (PaymentBatchType) {
    PaymentBatchType["ACH"] = "ACH";
    PaymentBatchType["ApplePay"] = "ApplePay";
    PaymentBatchType["Bit"] = "Bit";
    PaymentBatchType["Check"] = "Check";
    PaymentBatchType["CreditCard"] = "CreditCard";
    PaymentBatchType["Everything"] = "Everything";
    PaymentBatchType["GooglePay"] = "GooglePay";
    PaymentBatchType["NonCash"] = "NonCash";
    PaymentBatchType["OtherCash"] = "OtherCash";
    PaymentBatchType["Paypal"] = "Paypal";
    PaymentBatchType["Venmo"] = "Venmo";
})(PaymentBatchType || (PaymentBatchType = {}));
export var PaymentCategory;
(function (PaymentCategory) {
    PaymentCategory["DAF"] = "DAF";
    PaymentCategory["IRA"] = "IRA";
    PaymentCategory["MatchingGift"] = "MatchingGift";
    PaymentCategory["Payment"] = "Payment";
})(PaymentCategory || (PaymentCategory = {}));
export var PaymentChannel;
(function (PaymentChannel) {
    PaymentChannel["DigitalAds"] = "DigitalAds";
    PaymentChannel["Email"] = "Email";
    PaymentChannel["Mail"] = "Mail";
    PaymentChannel["Organic"] = "Organic";
    PaymentChannel["SMS"] = "SMS";
    PaymentChannel["SocialPost"] = "SocialPost";
    PaymentChannel["Telemarketing"] = "Telemarketing";
})(PaymentChannel || (PaymentChannel = {}));
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["Confirmed"] = "Confirmed";
    PaymentStatus["Unconfirmed"] = "Unconfirmed";
    PaymentStatus["Voided"] = "Voided";
})(PaymentStatus || (PaymentStatus = {}));
export var PaymentType;
(function (PaymentType) {
    PaymentType["ACH"] = "ACH";
    PaymentType["ApplePay"] = "ApplePay";
    PaymentType["Bequest"] = "Bequest";
    PaymentType["Bit"] = "Bit";
    PaymentType["Cash"] = "Cash";
    PaymentType["ChargeCard"] = "ChargeCard";
    PaymentType["Check"] = "Check";
    PaymentType["CreditCard"] = "CreditCard";
    PaymentType["Crypto"] = "Crypto";
    PaymentType["GooglePay"] = "GooglePay";
    PaymentType["InKind"] = "InKind";
    PaymentType["Other"] = "Other";
    PaymentType["Paypal"] = "Paypal";
    PaymentType["Stock"] = "Stock";
    PaymentType["Venmo"] = "Venmo";
    PaymentType["Wire"] = "Wire";
})(PaymentType || (PaymentType = {}));
export var PersonRole;
(function (PersonRole) {
    PersonRole["PrimaryContact"] = "PrimaryContact";
    PersonRole["SecondaryContact"] = "SecondaryContact";
})(PersonRole || (PersonRole = {}));
export var PhoneType;
(function (PhoneType) {
    PhoneType["Cell"] = "Cell";
    PhoneType["Fax"] = "Fax";
    PhoneType["Home"] = "Home";
    PhoneType["Main"] = "Main";
    PhoneType["Other"] = "Other";
    PhoneType["Work"] = "Work";
})(PhoneType || (PhoneType = {}));
export var PlannedGivingIntent;
(function (PlannedGivingIntent) {
    PlannedGivingIntent["ActivelyConsidering"] = "ActivelyConsidering";
    PlannedGivingIntent["Documented"] = "Documented";
    PlannedGivingIntent["InitialInterest"] = "InitialInterest";
    PlannedGivingIntent["Pledged"] = "Pledged";
})(PlannedGivingIntent || (PlannedGivingIntent = {}));
export var PlannedGivingStatus;
(function (PlannedGivingStatus) {
    PlannedGivingStatus["Approved"] = "Approved";
    PlannedGivingStatus["Denied"] = "Denied";
    PlannedGivingStatus["Pending"] = "Pending";
})(PlannedGivingStatus || (PlannedGivingStatus = {}));
export var PledgeApproveStatus;
(function (PledgeApproveStatus) {
    PledgeApproveStatus["Approved"] = "Approved";
    PledgeApproveStatus["Disapproved"] = "Disapproved";
    PledgeApproveStatus["Pending"] = "Pending";
})(PledgeApproveStatus || (PledgeApproveStatus = {}));
export var PledgeCategory;
(function (PledgeCategory) {
    PledgeCategory["Gift"] = "Gift";
    PledgeCategory["Grant"] = "Grant";
    PledgeCategory["Tribute"] = "Tribute";
})(PledgeCategory || (PledgeCategory = {}));
export var PledgePaidStatus;
(function (PledgePaidStatus) {
    PledgePaidStatus["NotPaid"] = "NotPaid";
    PledgePaidStatus["Paid"] = "Paid";
})(PledgePaidStatus || (PledgePaidStatus = {}));
export var RelationshipType;
(function (RelationshipType) {
    RelationshipType["Daughter"] = "Daughter";
    RelationshipType["ExHusband"] = "ExHusband";
    RelationshipType["ExWife"] = "ExWife";
    RelationshipType["Husband"] = "Husband";
    RelationshipType["Mother"] = "Mother";
    RelationshipType["Other"] = "Other";
    RelationshipType["Son"] = "Son";
    RelationshipType["Wife"] = "Wife";
})(RelationshipType || (RelationshipType = {}));
export var SalutationType;
(function (SalutationType) {
    SalutationType["Default"] = "Default";
    SalutationType["Husband_only_Nickname"] = "Husband_only_Nickname";
    SalutationType["Husband_only_with_No_Title"] = "Husband_only_with_No_Title";
    SalutationType["Husband_only_with_Title"] = "Husband_only_with_Title";
    SalutationType["Husbund_and_Wife_Nicknames"] = "Husbund_and_Wife_Nicknames";
    SalutationType["Husbund_and_Wife_with_No_Title"] = "Husbund_and_Wife_with_No_Title";
    SalutationType["Wife_and_Husbund_first_name_with_No_Title"] = "Wife_and_Husbund_first_name_with_No_Title";
    SalutationType["Wife_only_Nickname"] = "Wife_only_Nickname";
    SalutationType["Wife_only_with_No_Title"] = "Wife_only_with_No_Title";
    SalutationType["Wife_only_with_Title"] = "Wife_only_with_Title";
})(SalutationType || (SalutationType = {}));
export var SchoolGovernmentProgram;
(function (SchoolGovernmentProgram) {
    SchoolGovernmentProgram["DualEnrollment"] = "DualEnrollment";
    SchoolGovernmentProgram["EITC"] = "EITC";
    SchoolGovernmentProgram["FreeLunch"] = "FreeLunch";
    SchoolGovernmentProgram["MandatedServices"] = "MandatedServices";
    SchoolGovernmentProgram["STEM"] = "STEM";
    SchoolGovernmentProgram["Scholarships"] = "Scholarships";
    SchoolGovernmentProgram["SchoolHealthServices"] = "SchoolHealthServices";
    SchoolGovernmentProgram["Security"] = "Security";
    SchoolGovernmentProgram["TitlePrograms"] = "TitlePrograms";
    SchoolGovernmentProgram["Transportation"] = "Transportation";
    SchoolGovernmentProgram["UPK"] = "UPK";
})(SchoolGovernmentProgram || (SchoolGovernmentProgram = {}));
export var SendVia;
(function (SendVia) {
    SendVia["Email"] = "Email";
    SendVia["Mail"] = "Mail";
})(SendVia || (SendVia = {}));
export var SortOrder;
(function (SortOrder) {
    SortOrder["ASC"] = "ASC";
    SortOrder["DESC"] = "DESC";
})(SortOrder || (SortOrder = {}));
export var SubCommittee;
(function (SubCommittee) {
    SubCommittee["JewishAction"] = "JewishAction";
    SubCommittee["OU_Press"] = "OU_Press";
    SubCommittee["SummerPrograms"] = "SummerPrograms";
    SubCommittee["Teach_CA"] = "Teach_CA";
    SubCommittee["Teach_FL"] = "Teach_FL";
    SubCommittee["Teach_MD"] = "Teach_MD";
    SubCommittee["Teach_NJ"] = "Teach_NJ";
    SubCommittee["Teach_NYS"] = "Teach_NYS";
    SubCommittee["Teach_PA"] = "Teach_PA";
    SubCommittee["Website"] = "Website";
})(SubCommittee || (SubCommittee = {}));
export var SummaryModule;
(function (SummaryModule) {
    SummaryModule["Benefactors"] = "Benefactors";
    SummaryModule["BudgetReport"] = "BudgetReport";
    SummaryModule["CEOSummary"] = "CEOSummary";
    SummaryModule["DepartmentYTD"] = "DepartmentYTD";
    SummaryModule["FundraiserSummary"] = "FundraiserSummary";
    SummaryModule["JLICPrograms"] = "JLICPrograms";
    SummaryModule["OUIsraelQuartSummaries"] = "OUIsraelQuartSummaries";
    SummaryModule["PersonalPledges"] = "PersonalPledges";
    SummaryModule["QuartSummaries"] = "QuartSummaries";
    SummaryModule["TeachSchools"] = "TeachSchools";
})(SummaryModule || (SummaryModule = {}));
export var SurveyEventInterest;
(function (SurveyEventInterest) {
    SurveyEventInterest["Departmental_events"] = "Departmental_events";
    SurveyEventInterest["OU_Conventions"] = "OU_Conventions";
    SurveyEventInterest["Regional_events"] = "Regional_events";
    SurveyEventInterest["Scholars_in_Residence_Opportunities"] = "Scholars_in_Residence_Opportunities";
    SurveyEventInterest["Webinars"] = "Webinars";
})(SurveyEventInterest || (SurveyEventInterest = {}));
export var SurveyQuizOpt;
(function (SurveyQuizOpt) {
    SurveyQuizOpt["No"] = "No";
    SurveyQuizOpt["NotVery"] = "NotVery";
    SurveyQuizOpt["Not_at_all"] = "Not_at_all";
    SurveyQuizOpt["Somewhat"] = "Somewhat";
    SurveyQuizOpt["VeryMuch"] = "VeryMuch";
    SurveyQuizOpt["Yes"] = "Yes";
})(SurveyQuizOpt || (SurveyQuizOpt = {}));
export var TeachSchoolStatus;
(function (TeachSchoolStatus) {
    TeachSchoolStatus["Member"] = "Member";
    TeachSchoolStatus["Prospect"] = "Prospect";
    TeachSchoolStatus["Unidentified"] = "Unidentified";
})(TeachSchoolStatus || (TeachSchoolStatus = {}));
export var TimePeriod;
(function (TimePeriod) {
    TimePeriod["month"] = "month";
    TimePeriod["quarter"] = "quarter";
    TimePeriod["week"] = "week";
    TimePeriod["year"] = "year";
})(TimePeriod || (TimePeriod = {}));
export var Title;
(function (Title) {
    Title["Admiral"] = "Admiral";
    Title["Ambassador"] = "Ambassador";
    Title["Assemblyman"] = "Assemblyman";
    Title["Bishop"] = "Bishop";
    Title["Brother"] = "Brother";
    Title["Cantor"] = "Cantor";
    Title["Captain"] = "Captain";
    Title["Chaplain"] = "Chaplain";
    Title["Chief_rabbi"] = "Chief_rabbi";
    Title["Child"] = "Child";
    Title["Cmdr"] = "Cmdr";
    Title["Col"] = "Col";
    Title["Commisioner"] = "Commisioner";
    Title["Congressman"] = "Congressman";
    Title["Councilman"] = "Councilman";
    Title["Dean"] = "Dean";
    Title["Dr"] = "Dr";
    Title["Drs"] = "Drs";
    Title["Esq"] = "Esq";
    Title["Father"] = "Father";
    Title["General"] = "General";
    Title["Governor"] = "Governor";
    Title["Hon"] = "Hon";
    Title["Horav"] = "Horav";
    Title["Infant"] = "Infant";
    Title["Judge"] = "Judge";
    Title["Lt"] = "Lt";
    Title["Lt_cmdr"] = "Lt_cmdr";
    Title["Lt_col"] = "Lt_col";
    Title["Major"] = "Major";
    Title["Miss"] = "Miss";
    Title["Mr"] = "Mr";
    Title["Mrs"] = "Mrs";
    Title["Ms"] = "Ms";
    Title["Pastor"] = "Pastor";
    Title["Professor"] = "Professor";
    Title["Rabbi"] = "Rabbi";
    Title["Rabbi_dr"] = "Rabbi_dr";
    Title["Rebbetzin"] = "Rebbetzin";
    Title["Rep"] = "Rep";
    Title["Rev"] = "Rev";
    Title["Senator"] = "Senator";
    Title["Sgt"] = "Sgt";
})(Title || (Title = {}));
export var UserRole;
(function (UserRole) {
    UserRole["AddCampaign"] = "AddCampaign";
    UserRole["AddPledge"] = "AddPledge";
    UserRole["AssignContacts"] = "AssignContacts";
    UserRole["ChangeDonorKind"] = "ChangeDonorKind";
    UserRole["CheckPaymentEdit"] = "CheckPaymentEdit";
    UserRole["ChecksUpload"] = "ChecksUpload";
    UserRole["DeleteContactInfo"] = "DeleteContactInfo";
    UserRole["DeletePledgeAndPayment"] = "DeletePledgeAndPayment";
    UserRole["EditFundrasingBudget"] = "EditFundrasingBudget";
    UserRole["EditUsers"] = "EditUsers";
    UserRole["GenerateInvoicesAndReceipts"] = "GenerateInvoicesAndReceipts";
    UserRole["ManageBenefactor"] = "ManageBenefactor";
    UserRole["ManageBoardMembers"] = "ManageBoardMembers";
    UserRole["ManageCommissionAndCommitteeMembers"] = "ManageCommissionAndCommitteeMembers";
    UserRole["ManageEmployees"] = "ManageEmployees";
    UserRole["ManageGrants"] = "ManageGrants";
    UserRole["ManageLibraryFiles"] = "ManageLibraryFiles";
    UserRole["ManageNetworkRegions"] = "ManageNetworkRegions";
    UserRole["ManageRM"] = "ManageRM";
    UserRole["ManageSubscriptions"] = "ManageSubscriptions";
    UserRole["MergeDuplicates"] = "MergeDuplicates";
    UserRole["MovePledgeAndPayment"] = "MovePledgeAndPayment";
    UserRole["ViewBatches"] = "ViewBatches";
    UserRole["ViewBoardMembers"] = "ViewBoardMembers";
    UserRole["ViewCheckScans"] = "ViewCheckScans";
    UserRole["ViewDepartmentStaff"] = "ViewDepartmentStaff";
    UserRole["ViewEmployees"] = "ViewEmployees";
    UserRole["ViewGoalsReport"] = "ViewGoalsReport";
    UserRole["ViewLeadershipReports"] = "ViewLeadershipReports";
    UserRole["ViewNewDonors"] = "ViewNewDonors";
    UserRole["ViewOuMemebers"] = "ViewOuMemebers";
    UserRole["ViewPending"] = "ViewPending";
    UserRole["ViewQiuckLookup"] = "ViewQiuckLookup";
    UserRole["ViewRecurringPayments"] = "ViewRecurringPayments";
    UserRole["ViewTelemarketing"] = "ViewTelemarketing";
    UserRole["ViewTurnaroundPayments"] = "ViewTurnaroundPayments";
    UserRole["ViewUsers"] = "ViewUsers";
    UserRole["Write"] = "Write";
})(UserRole || (UserRole = {}));
export var UserType;
(function (UserType) {
    UserType["Accounting"] = "Accounting";
    UserType["CEO"] = "CEO";
    UserType["DataServices"] = "DataServices";
    UserType["DepartmentManager"] = "DepartmentManager";
    UserType["Developer"] = "Developer";
    UserType["Fundraiser"] = "Fundraiser";
    UserType["RegionManager"] = "RegionManager";
    UserType["Support"] = "Support";
})(UserType || (UserType = {}));
export var WireBank;
(function (WireBank) {
    WireBank["Chase"] = "Chase";
    WireBank["Fidelity"] = "Fidelity";
    WireBank["IDB_Donations"] = "IDB_Donations";
    WireBank["IDB_Kosher"] = "IDB_Kosher";
    WireBank["IDB_Other_Receipts"] = "IDB_Other_Receipts";
    WireBank["Other"] = "Other";
})(WireBank || (WireBank = {}));
